.body {
  padding: 0!important;
}

.wrapper {
  min-width: 1045px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.automations {
  flex: 1 1 60%;
  max-width: 800px;
  padding: 40px 80px 40px 40px;
  min-width: 640px;
}

.postSearch {
  width: 500px;
  padding: 40px 40px 0 0;
}
