@import "../config/includes";

.wrapper {
  flex: 0 0 auto;
  background-color: $white;
  border-bottom: solid 1px $grey300;
  padding: 0 64px;
  z-index: $modalBase - 100;
  display: flex;
  align-items: center;
}

.buttons {
  flex: 0 0 auto;
  > li {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.trial {
  flex: 0 0 auto;
  padding-left: 40px;
}
