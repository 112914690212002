@import "../config/includes";

.wrapper {
    color: $textColor;
}

.label {
    @include fontSize15;
    font-weight: 600;
    padding-bottom: 24px;
}

.children {
    @include fontSize15;
}
