@import "config/includes";

.container {

}

.slickSlider {
  margin: 0 -12px;
}

:global {

  /*# sourceMappingURL= https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css
  https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css
  */

  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
      outline: 0;
    }

    &.dragging {
      cursor: pointer;
    }
  }

  .slick-slide {
    display: none;
    float: left;
    min-height: 1px;

    > img {
      display: block
    }

    &.slick-loading {
      > img {
        display: none
      }
    }

    &.dragging {
      > img {
        pointer-events: none
      }
    }

  }

  .slick-slider {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-track {
    top: 0;
    left: 0;

    &:after {
      clear: both
    }

  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden
  }

  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track:after,
  .slick-track:before {
    display: table;
    content: ''
  }

  .slick-initialized .slick-slide {
    display: block;

    >div {
      height: 100%;
      >div {
        height: 100%;
        display: flex!important;

        &:focus {
          outline: none;
        }
      }
    }
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
  }
  .slick-arrow.slick-hidden {
    display: none
  }

  .slick-dots,
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0
  }
  .slick-dots li button:before,
  .slick-next:before,
  .slick-prev:before {
    // font-family:slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .slick-loading .slick-list {
    //background:url(ajax-loader.gif) center center no-repeat $white
  }

  .slick-next {
    right: -25px;

    &:before {
      content: '';
      display: block;
      right: 100%;
      top: 50%;
      margin-top: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $grey500;
    }

  }

  .slick-prev {
    left: -25px;

    &:before {
      content: '';
      display: block;
      right: 100%;
      top: 50%;
      margin-top: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid $grey500;
      border-bottom: 10px solid transparent;
      border-left: 10px solid transparent;
    }

  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0;

    &:before {
      font-size: 20px;
      line-height: 1;
      opacity: .75;
      color: $white
    }

    &:hover:before {
      opacity: 1
    }

    &:focus:before {
      opacity: 1
    }

    &.slick-disabled:before {
      opacity: .25;
    }

  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px
  }

  .slick-dots {
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;

    > li {
      position: relative;
      display: inline-block;
      width: 26px;
      margin: 12px 5px;
      padding: 0;
      cursor: pointer;

      &.slick-active {
        > button {

          &:before {
            opacity: .75;
            color: $black;
          }
        }
      }

      > button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 26px;
        height: 26px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: 0;
        background: 0;

        &:hover {
          outline: 0;

          &:before {
            opacity: 1;
          }
        }

        &:focus {
          outline: 0;
          &:before {
            opacity: 1;
          }
        }

        &:before {
          font-size: 26px;
          line-height: 26px;
          position: absolute;
          top: 0;
          left: 0;
          width: 26px;
          height: 26px;
          content: '•';
          text-align: center;
          opacity: .25;
          color: $black;

        }

      }
    }

  }

  /**
   * overwrite some of the original css rules
   */
  .slick-list {
    height: auto !important;
  }

  .slick-track {
    display: flex !important;
    align-items: stretch !important;
  }

  .slick-slide {
    float: none !important;
    flex: 0 0 auto !important;
    display: flex !important;
    flex-direction: column !important;
  }

}
