@import "../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.headerRow {
  flex: 0 0 auto;
  display: flex;
  padding: 24px;
  flex-direction: row;

  .title {
    flex: 1 1 auto;
    display: flex;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      background: $grey200;
      margin-right: 8px;
    }

    .text {
      width: 200px;
      height: 18px;
      border-radius: 8px;
      background: $grey200;
    }
  }

  .controls {
    flex: 0 0 auto;
    display: flex;
  }

  .control {
    margin-left: 10px;
  }
}

.content {
  flex: 1 1 auto;
  border-radius: 8px;
  padding: 24px;
}
