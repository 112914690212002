.floatingListItem {
  position: relative;
  list-style-type: none;
  padding: 0 12px 30px;
  box-sizing: border-box;
  min-width: 200px;
  flex: 0 0 auto;
  display: flex;
}

.discover {
  @media screen and (min-width: 2000px) {
    width: 25%
  }

  @media screen and (min-width: 1500px) and (max-width: 1999px) {
    width: 33.33%;
  }

  @media screen and (min-width: 1000px) and (max-width: 1499px) {
    width: 50%;
  }

  @media screen and (max-width: 999px) {
    width: 100%;
  }
}