@import "../../../config/includes";

.wrapper {
  border-radius: 20px;
  border: solid 1px $grey400;
  padding: 24px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.mainList {
  display: flex;
  flex-direction: column;
}

.mainListHeader {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
}

.mainListTitle {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  @include fontSize18;
  font-weight: 600;
}

.icon {
  font-size: 24px;
  padding-right: 12px;
}

.newRule {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.tagRuleDescription {
  padding-bottom: 24px;
}
