@import "config/includes";

.header {
  flex: 0 0 auto;
  margin: 18px 24px;
  display: flex;
  align-items: center;

  .networkIcon {
    flex: 0 0 auto;
    font-size: 18px;
    margin-right: 8px;
    margin-top: 3px;
    margin-bottom: auto;
  }

  .nameWrapper {
    min-width: 30px;
    flex: 1 1 auto;
  }

  .name {
    color: $textColor;
    @include fontSize15;
    font-weight: 600;
  }
}
