.globalSelectors {
  padding-bottom: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter {
  flex: 1 1 auto;
}

.row {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

.feedback {
  padding-bottom: 16px;
}

.globalCheckbox {
  flex: 1 1 auto;
}

.orderBy {
  flex: 0 0 auto;
}

