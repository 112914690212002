@import "../config/includes";

.button {
  cursor: pointer;
  border: none;
  display: block;
  color: $grey500;
  @include fontSize15;
  font-weight: 600;
  padding: 21px 0 18px 0;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  outline: none;

  &:hover {
    color: $blue700;
    text-decoration: none;
  }
}

.activeTab {
  color: $blue700;
  border-bottom: 4px solid $primaryActionColor;
}
