@import "../../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $textColor;
  justify-content: space-between;

  &:hover {
    background-color: $blue200;

    .markAction {
      opacity: 1;
    }
  }
}

.left {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  min-width: 350px;
  padding: 20px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

}

.createTime {
  flex: 0 0 auto;
}

.markAction {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  opacity: 0;
}

.type {
  flex: 0 0 auto;
  padding-right: 20px;

  .icon {
    font-size: 24px;
    color: $grey500;
  }
}

.message {
}

.unRead {
  background-color: $blue100;
  border: solid 1px $blue500;
}

.markAction {
  opacity: 0;
  padding-left: 20px;
}
