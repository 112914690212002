.wrapper {
  display: flex;
  justify-content: space-between;
}

.userInfo {
  padding-bottom: 32px;
}

.warning {
  padding-top: 20px;
}

.editableWrapper {
  display: flex;
}

.editButton {
  margin-left: 8px;
}
