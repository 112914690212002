@import "../config/includes";

.wrapper {
  background-color: $white;
}

.header {
  flex: 0 0 auto;
  position: relative;
  padding: 26px 64px 0 64px;
  min-height: 40px;
  display: flex;
  flex-direction: column;

  &.fixed {
    position: fixed;
    top: 68px;
    left: 72px;
    right: 0;
    padding: 14px 64px;
    z-index: 99; // 1 below the top nav, but above all inner content elements
    border-bottom: 1px solid $grey200;
    background-color: rgba(255, 255, 255, 0.85);
  }

  &.withSidebarOpen {
    right: 280px;
  }

  &.alignBottom {
    padding: 0 64px;
    min-height: 104px;
    justify-content: flex-end;
  }
}

.row {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.bottom {
    padding-top: 26px;
  }
}

.column {
  min-width: 30px;
  flex: 0 0 auto;
  &.flexGrow {
    flex: 1 1 auto;
  }

  &.flexShrink {
    flex: 0 1 auto;
  }

  &.growNoShrink {
    flex: 1 0 auto;
  }
}

.headline {
  color: $midnight900;
  font-weight: 600;
}

.headline.big {
  @include fontSize30;

  // we have a fixed height to hit, so we set both the height and line-height to the same value
  height: 40px;
  line-height: 40px;
}

.headline.small {
  @include fontSize18;
}

.detail {
  @include fontSize15;
  font-weight: 600;
  color: $midnight900;
}
