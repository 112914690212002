@import "../config/includes";

.wrapper {
  padding: 8px 15px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;

    .markAction {
      opacity: 1;
    }
  }
}

.unRead {
  background-color: $blue100;

  &:hover {
    background-color: $blue200 !important;
    cursor: pointer;
  }
}

.read {
  &:hover {
    background-color: $blue50;
    cursor: pointer;
  }
}

.type {
  padding-right: 14px;
}

.icon {
  font-size: 24px;
  color: $grey500;
}

.body {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include fontSize13;
  color: $textColor;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
}

.message {
  width: 240px;
  padding-bottom: 2px;
}

.relativeTime {
  display: flex;
}

.markAction {
  opacity: 0;
  padding-left: 5px;
}

.link {
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: $blue700;
  }
}
