@import "../config/includes";

.sidebarNav {
  width: 72px;
  background-color: $mainNavigation;
  box-shadow: 1px 0 8px 0 rgba(32, 62, 73, 0.25);
  z-index: $modalBase - 100;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.topMenus {
  flex: 1 1 auto;
  overflow-y: auto;
}

.bottomMenu {
  flex: 0 0 auto;
  padding-bottom: 12px;
}
