.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.postTiles {
  padding: 0 24px;
}

.isLoading {
  opacity: 0.5;
  pointer-events: none;
}

.navigationScroller {
  padding-top: 28px;
  display: flex;
  justify-content: center;
}

.emptyImage {
  width: 490px;
  height: 260px;
}

.warning {
  padding-bottom: 24px;
}