@import "config/includes";

.container {
  display: flex;
  align-items: center;
  flex: 1 1 auto
}

.icon {
  flex: 0 0 auto;
  padding-right: 8px;

  i {
    font-weight: 400 !important;
  }
}

.pageHeadline {
  flex: 1 1 auto;
  width: 40px;
}

.big {
  font-size: 24px;
}

.small {
  font-size: 24px;
}
