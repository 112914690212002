@import "config/includes";

.dragHandleWrapper {
  display: flex;
  align-items: center;

  .dragHandleSpacer {
    flex: 0 0 auto;
    width: 0;
    height: 24px;
    border-left: 1px solid $grey300;
    margin-right: 8px;
  }

  .dragHandleButtonWrapper {
    flex: 0 0 auto;
  }
}
