@import "../../config/includes";

.platformImg {
  padding-right: 12px;
}

.ellipsis {
  min-width: 0;
}

.flexDiv {
  display: flex;
  align-items: center;
  min-width: 0;
}

.numberColumn {
  text-align: right;
}
