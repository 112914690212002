@import "config/includes";

.tile {
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0 1px 3px $grey400;
  height: 190px;
  border: 2px solid $white;
  border-radius: 8px;
  width: 244px;
  outline: none;

  &:hover {
    border: 2px solid $primaryActionColor;
    @include zoom;
  }

}

.image {
  flex: 1 1 auto;
  background-repeat: no-repeat;
  background-position: center top;
  margin: 0 24px 20px;
  background-size: cover;
}

