@import "../config/includes";

.wrapper {
  color: $textColor;
}

.labelSection {
  display: flex;
  padding-bottom: 12px;
}

.label {
  @include fontSize15;
  font-weight: 600;
}

.children {
  @include fontSize15;
  word-break: break-word;
}

.moreInfo {
  padding-left: 8px;
}
