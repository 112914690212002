@import "config/includes";

.tile {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0 1px 3px $grey400;
  min-height: 460px;
  border: 2px solid $white;
  border-radius: 8px;

  &:hover {
    border: 2px solid $primaryActionColor;
    @include zoom;
  }

  .buttons {
    float: right;
  }
}

.tileImage {
  background-size: cover;
}
