@import "../../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.controls {
  display: flex;
  flex-direction: column;
  padding: 26px 24px;
  background-color: $white;
  justify-content: center;
  flex: 0 0 auto;

  >.button {
    margin-bottom: 10px;
  }
}

.selectionInfo {
  padding: 0 24px 12px 24px;
}

.selectedTags {
  overflow-y: auto;
}

.tagListItem {
  padding-right: 24px;

  &:hover {
    background-color: $blue50;
    i { // this is for the deselect icon to have similar background as hover
      background-color: $blue50;
    }
  }
}

