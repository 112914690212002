@import "../../../config/includes";

.wrapper {
  position: relative;
  list-style-type: none;
  padding: 0 12px 24px 12px;
  box-sizing: border-box;
  min-width: 200px;
  flex: 0 0 auto;
}

.post {
  @media screen and (min-width: 2170px) {
    width: 25%
  }

  @media screen and (min-width: 1746px) and (max-width: 2169px) {
    width: 33.33%;
  }

  @media screen and (min-width: 1322px) and (max-width: 1745px) {
    width: 50%;
  }

  @media screen and (max-width: 1321px) {
    width: 100%;
  }
}
