@import "config/includes";

.body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 0 24px 20px 24px;

  .image {
    flex: 0 0 auto;
    height: 170px;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .description {
    flex: 0 0 auto;
    margin-top: 20px;
    @include fontSize15;
    color: $textColor;
  }

  .tags {
    flex: 0 0 auto;
    margin-top: 20px;

    &:hover {
      cursor: auto;
    }
  }
}
