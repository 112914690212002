@import "../../config/includes";

.wrapper {
  border-radius: 20px;
  border: solid 1px $primaryActionColor;
  background-color: $blue50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 20px;
}

.text {
  @include fontSize15;
  font-weight: 600;
  line-height: 1;
  color: $blue700;
}

.icon {
  font-size: 24px;
  color: $primaryActionColor;
  padding-left: 12px;
}
