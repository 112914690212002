@import "../config/includes";

.item {
  height: 48px;
  display: flex;
  padding-left: 17px;
  align-items: center;
}

.icon {
  border-radius: 8px;
  width: 24px;
  height: 24px;
  background: $grey200;
}

.text {
  border-radius: 8px;
  width: 167px;
  background: $grey200;
  height: 16px;
  margin-left: 8px;
}

.list {
  list-style-type: none;

  li {
    &:nth-child(4n+1) {
      .text {
        width: 167px;
      }
    }
    &:nth-child(4n+2) {
      .text {
        width: 227px;
      }
    }
    &:nth-child(4n+3) {
      .text {
        width: 167px;
      }
    }
    &:nth-child(4n+4) {
      .text {
        width: 197px;
      }
    }
  }
}
