@import 'config/includes';

:global(.globalTileForHover) {
  &:hover {
    .footer {
      margin-right: 0;
      margin-left: 0;
      .left {
        margin-left: -2px;
      }
      .right {
        margin-right: -2px;
      }
    }
  }
}

.footer {
  flex: 0 0 auto;
  padding: 16px 24px;
  border-top: 1px solid $grey300;
  display: flex;
  align-items: center;
  margin-left: -2px;
  margin-right: -2px;

  &:hover {
    cursor: auto;
  }

  .left {
    flex: 1 1 auto;

    .usage {
      @include fontSize13;
      color: $grey400;
    }
  }

  .right {
    flex: 1 1 auto;
  }
}
