@import "../../config/includes";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image {
  width: 95%;
}

.expander {
  width: 100%;
  height: 64px;
  background-color: $white;
}
