@import "../config/includes";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.backButton {
  padding-right: 12px;
}

.headline {
  width: 0;
  display: flex;
  flex: 1 1 auto;
}

.bottomRow {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.selector {
  min-width: 600px;
}
