@import "config/includes";

.nav {
  display: block;
  color: $textColor;
}

.list {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;

  > li {
    margin: 0 12px;
  }
}

.listItem {
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &.selected {
    background-color: $blue100;
    border: 1px solid $primaryActionColor;
    color: $blue700;
  }

  &:hover {
    background-color: $midnight100;
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.icon {
  font-size: 24px;
  color: $grey500;
}
