@import "config/includes";

.wrapper {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  min-width: 30px;

  .networkIcon {
    flex: 0 0 auto;
    font-size: 24px;
    margin-right: 12px;
  }

  .dataSourceName {
    color: $textColor;
    @include fontSize18;
    font-weight: 600;
  }

  .ellipsisWrapper {
    min-width: 30px;
  }

  &.small {
    .dataSourceName {
      @include fontSize18;
    }
  }

  &.large {
    .dataSourceName {
      @include fontSize30;
      color: $midnight900;
    }
  }
}
