@import "config/includes";

.wrapper {
  display: flex;
  align-items: center;
  min-width: 0;
  flex-direction: column;
}

.iconWrapper {
  flex: 0 0 auto;
}

.name {
  font-weight: 600;
  @include fontSize10;
  padding-top: 4px;
  text-align: center;
  width: 60px;
}
