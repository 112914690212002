@import "config/includes";

.item {
  padding: 11px 20px;
  color: $grey400;
}

.highlight {
  color: $textColor;
  background-color: transparent;
  font-weight: 600;
}
