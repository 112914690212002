@import 'config/includes';

.wrapper {
  padding: 12px 16px;
  display: flex;
  align-items: center;

  .networkIcon {
    flex: 0 0 auto;
    font-size: 24px;
    margin-right: 8px;
  }

  .name {
    flex: 1 1 auto;
    color: $textColor;
    @include fontSize13;
  }

  .icon {
    color: $grey500;
    font-size: 24px;
    margin-left: 8px;
  }
}

.visualization {
  flex: 0 0 auto;
  padding-left: 6px;
}
