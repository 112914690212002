@import "../../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  padding-bottom: 24px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.title {
  @include fontSize15;
  font-weight: 600;
  flex: 1 1 auto;
}

.icon {
  @include fontSize18;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.postTile {
  padding-bottom: 24px;
}

.postTileBackground {
  background-color: $midnightGrey;
}

.postTileSkeleton {
  width: 100%;
}

.emptyImage {
  width: inherit;
  height: 200px;
}
