@import "config/includes";

.wrapper {

}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .networkIcon {
    display: block;
    flex: 0 0 auto;
    margin-right: 12px;
  }

  .metricName {
    flex: 0 0 auto;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .headerLeft {
    flex: 0 1 auto;
    display: flex;
    min-width: 500px;
  }

  .headerRight {
    padding-left: 10px;
    flex: 0 0 auto;
  }
}

.metric {
  margin-top: 32px;
  height: calc(80vh - 76px); // 76px for header
  min-height: 325px;
  display: flex;
}
