@import "../../../config/includes";

.wrapper {
  display: flex;
  align-items: center;

  &:hover :global(.list-hover-button-opacity) {
    opacity: 1;
  }

  &:hover {

    cursor: pointer;
    :global {
      .authUserForHover {
        color: $blue800 !important;
        text-decoration: underline !important;
      }
    }
  }
}

.left {
  flex: 1;
  display: flex;
  align-items: center;
  //padding-right: 10px;
  cursor: pointer;
  padding: 14px 24px;
}

.toggleButton {
  padding-right: 20px;
}

.icon {
  font-size: 24px;
}

.userInfo {
  flex: 1;
  display: flex;
  align-items: center;
}

.networkIcon {
  flex: 0 0 auto;
  padding-right: 20px;
  line-height: 0;
}

.authUserLabel {
  flex: 1;
  min-width: 150px;
}

.section {
  flex: 1;
  min-width: 0;
}

.button {
  padding: 0 24px;
}

.updateInfo {
  display: flex;
}
