@import "../config/includes";

.wrapper {
  min-width: 500px;
  display: flex;
}

.rightSection {
  flex: 60%;
}

.leftSection {
  flex: 40%;
  padding-right: 24px;
}

.metaInformation {
  padding-bottom: 12px;
}

.sampleTile {
  border-radius: 8px;
  background: $white;
  width: 100%;
  height: 410px;
  display: flex;
}

.tile {
  margin-bottom: 24px;
}

.noInfoText {
  color: $grey400;
}

.section {
  padding: 24px 24px 20px 24px;
}

.subsections {
  padding-bottom: 24px;
}

.texts {
  @include fontSize15;
  color: $textColor;

  > p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.screenShot {
  border-radius: 8px;
  margin-bottom: 40px;
}

.image {
  width: 100%;
}

.metricItemPadding {
  padding-bottom: 24px;
}

.bottomPadding {
  padding-bottom: 24px;
}

.metricItem {
  display: block;
  color: $blue700;
}

.header {
  padding-top: 3px;
}

.dataSourceHint {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.dataSourceHintText {
  margin-top: 8px;
  @include fontSize15;
  color: $textColor;
}

.loadingSection {
  padding-bottom: 40px;
}

.dataSources {
  padding-top: 12px;
}
