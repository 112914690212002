@import "../../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 8px;
}

.tableContext {
  margin-right: 20px;
  margin-left: 8px;
}

.inActive {
  background-color: $grey300;
}

.active {
  background-color: $green700;
}
