@import "../../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;

  &:hover {
    background-color: $blue50;
    cursor: pointer;
  }
}

.imgWrapper {
  padding-right: 12px;
}

.right {
  flex: 0 0 auto;
}

.icon {
  font-size: 24px;
  margin-right: 1px; // somehow the icon can overlap over the 24px font-size in width, thus we make sure we move it as a whole one pixel to the left
}

.left {
  flex: 1 1 auto;
  min-width: 0;
  //padding-right: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 24px;
}

.message {
  display: flex;
  align-items: center;
  @include fontSize13;
  min-width: 0;
}

.noMessage {
  color: $grey400;
}
