@import "../../config/includes";

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.listItem {
  border-bottom: 1px solid $grey300;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $textColor;
  height: 52px;
  background-color: $white;

  &:hover {
    cursor: pointer;
    background-color: $blue50;

    .dataSourceName {
      color: $blue800 !important;
      text-decoration: underline !important;
    }
  }
}

.dataSourceNameWrapper {
  min-width: 0;
}

.left {
  flex: 1 1 35%;
  display: flex;
  align-items: center;
  min-width: 0;
}

.right {
  flex: 1 1 65%;
  display: flex;
  align-items: center;
}

.network {
  padding-left: 24px;
  padding-right: 10px;
}

.privateStatistic {
  padding-right: 24px;
}

.networkIcon {
  font-size: 24px;
}

.icon {
  font-size: 24px;
  color: $grey500;
}

.item {
  padding: 20px
}
