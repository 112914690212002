@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  min-width: 0;
}

.indicatorSection {
  display: flex;
  flex: 0 0 auto;
  min-height: 24px;
  min-width: 24px;
  max-width: 46px;
}

.indicator {
  display: flex;
  flex: 1 1 auto;
  padding: 0 8px;
  border-radius: 24px;
  color: $white;
  @include fontSize13;
  align-items: center;
  justify-content: center;
  background-color: $green600;
}

.error {
  background-color: $faceliftOrange;
}

.message {
  padding-left: 8px;
  min-width: 0;
}
