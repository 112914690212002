@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.controls {
  display: flex;
  flex-direction: column;
  padding: 26px 24px;
  background-color: $white;
  justify-content: center;
  flex: 0 0 auto;

  >.button {
    margin-bottom: 10px;
  }
}

.selectionInfo {
  padding: 0 24px 12px 24px;
}

.reportsOverviews {
  padding: 0 24px 12px 24px;
}

.reportsOverviewItem {
  padding-bottom: 24px;
}
