@import "../../config/includes";

.link {
  min-width: 0;
}

.wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  &:hover {
    .iconWrapper {
      display: flex;
      min-width: 0;
    }
  }
}

.iconWrapper {
  display: none;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}

.icon {
  font-size: 32px;
  color: $white;
}
