@import "../../config/includes";

.headline {
  @include fontSize18;
  font-weight: 600;
  padding-bottom: 33px;
  border-bottom: 1px solid $grey300;
}

.tagTypeWrapper {
  padding-top: 32px;

  .tagTypeHeadline {
    font-weight: 600;
  }

  .tagList {
    margin-top: 8px;
    list-style-type: none;

    li.tagWrapper {
      padding-top: 12px;
      display: flex;
      align-items: center;

      .icon {
        @include fontSize18;
        color: inherit;
        padding-right: 8px;
      }

      a.tag {
        display: flex;
        align-items: center;
        color: $grey500;

        &:hover {
          color: $blue700;
          text-decoration: none;
        }
      }

      a.activeTag {
        color: $blue700;
        position: relative;

        .checkmark {
          position: absolute;
          left: -32px; // 24px + 8px
          font-size: 24px;
        }
      }
    }
  }
}

.metricFilters {
  padding: 32px 0 32px 0;
  border-bottom: 1px solid $grey300;
}

.metricType {
  padding-bottom: 12px;
}

.radioText {
  color: $grey500;
}
