@import "../config/includes";
.dataSource {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  padding-bottom: 8px;
}
