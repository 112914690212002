.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topNav {
  flex: 0 0 auto;
}

.body {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  overflow: auto;
  z-index: 1;
}
