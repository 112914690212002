.logo {
  display: block;
  max-width: 100px;
  max-height: 40px;
  min-width: 24px;

  &.big {
    max-width: 216px;
    max-height: 80px;
  }

  &.export {
    max-width: 150px;
    max-height: 50px;
  }
}
