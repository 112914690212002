@import "../config/includes";

.wrapper {
  display: flex;
  flex: 1 1 auto;
  padding: 20px;
  @include fontSize13;
  width: 520px;
}

.title {
  font-weight: 600;
  padding-bottom: 12px;
}

.left {
  width: 240px;
  padding-right: 20px;
  border-right: 1px solid $grey300;

  .error {
    padding-bottom: 12px;
    min-width: 0;
  }
}

.right {
  width: 240px;
  padding-left: 20px;

  .profileInfo {
    padding-bottom: 20px;
    min-width: 0;
  }
}

.buttons {
  padding-top: 22px;
}

.button {
  padding-bottom: 12px;
}
