@import "../../config/includes";

.wrapper {
  height: 52px;
  width: 4px;

  &.error {
    background-color: $red600;
  }
}
