@import "../../config/includes";

.card {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0 1px 3px $grey400;
  min-height: 300px;
  max-height: 600px;
  border-radius: 8px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    border: 1px solid $blue500;
  }
}

.cardTitle {
  padding: 12px;
}

.postImage {
  padding-bottom: 12px;
}

.cardBody {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px 12px;
  word-break: break-word;
}

.content {
  flex: 1 1 auto;
  height: 100px;
}

.kpis {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.footer {
  flex: 0 0 auto;
  border-top: 1px solid $grey400;
  padding: 0 12px 12px 12px;
}
