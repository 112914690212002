@import "../../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.item {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $grey300;
}
