@import "../../config/includes";

.img {
  width: 500px;
}

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  .imageSection {
    margin-bottom: 26px;
  }

  .title {
    @include fontSize30;
    line-height: 1;
    color: $grey500;
    text-align: center;
    margin-bottom: 32px;
  }

  .message {
    @include fontSize15;
    color: $grey500;
    text-align: center;
    margin-bottom: 40px;
    max-width: 600px;
  }

  .buttons {
    margin-bottom: 40px;
  }
}
