@import "../../../config/includes";

.listItem {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 800px;
  background-color: $white;
}

.listItemBody {
  padding: 0 68px 14px 68px;
}

.network {
  padding-top: 18px;
}

.header {
  height: 52px;
}
