@import "config/includes";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 0 50px 0;

  :global(.slick-prev) {
    left: -20px;
  }
  :global(.slick-next) {
    right: -20px;
  }
}

.loadingWrapper {
  height: 478px;
  text-align: center;

  .loadingText {
    display: inline-block;
    vertical-align: middle;
    line-height: 478px;
    color: $grey400;
  }
}

.slickContainer {
  margin: 0 -12px;
  position: relative;
}

.sliderItem {
  flex: 1 1 auto;
  display: flex;
  padding: 0 12px;
  margin-bottom: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
}

.title {
  @include fontSize18;
  font-weight: 600;
  color: $textColor;
}

.showAllLink {
  &:hover {
    text-decoration: underline;
  }
}
