@import "../config/includes";

.table {
  @include fontSize11;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;

  margin: 0 auto;
  clear: both;
  border-collapse: separate;
}

.tableHeaderCell {
  padding: 7px;
  font-weight: 600;
  @include fontSize11;
  line-height: normal; // Rare exception of us overwriting the line-height as we want to minimize whitespace in table headers in exports
  color: $textColor;
  box-sizing: content-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  position: relative;
  border-bottom: 1px solid $grey200;
  vertical-align: bottom;

  &.activelySorted {
    padding-right: 12px;
  }

  .icon {
    font-size: 20px;
    position: absolute;
    right: -2px;
    bottom: 2px;
  }
}

.tableCell {
  padding: 7px;
}

.tableBodyCell {
  border-bottom: 1px solid $grey200;
  &.lastTableRow {
    border-bottom: none;
  }
}

.wordwrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.noData {
  padding: 10px 0;
}


.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

.centerAlign {
  text-align: center;
}
