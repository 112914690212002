@import "config/includes";

.tags {
  list-style-type: none;
  overflow: auto;
  padding: 0;
  margin: 0;
  margin-bottom: -10px;

  > li {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.buttons:after {
  content: '';
  display: block;
  clear: both;
}
