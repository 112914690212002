@import "../../config/includes";

.flex {
  flex: 1 1 auto;
  display: flex;
}

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  overflow: auto;
  height: 100%;
}

.label {
  padding-bottom: 12px;
  @include fontSize18;
  color: $textColor;
}

.singleValue {
  font-size: inherit; // the font-size is provided by the parent component (ScaleText)
  padding-bottom: 20px;
}

.change {
  display: flex;
}

.changePercent {
  display: flex;
}
