@import "config/includes";
.wrapper {
  @include fontSize15;
  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0;
  }
}

.headline {
  font-weight: 600;
  padding-bottom: 12px;
}

.message {

}

.content {
  padding-top: 12px;
}

.buttons {
  padding-top: 12px;
}
