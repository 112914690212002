@import "config/includes";

.metricHeader {
  position: relative;
}

.topRow {
  display: flex;

  &.small {
    flex-wrap: wrap;
    justify-content: center;
    &.grid {
      > .right {
        padding: 0 0 17px 0;
      }
      > .left {
        padding: 20px 24px 5px 24px;
        min-height: 32px;
      }
    }
  }

  > .left {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    min-width: 30px;

    &.withHover {
      @include zoom;
      &:hover {
        :global(.metricNameForHover) {
          text-decoration: underline;
        }
      }
    }

    .selectionSummaryWrapper {
      flex: 1 1 auto;
      margin-left: 10px;
      width: 30px;
    }
  }

  > .right {
    flex: 0 0 auto;
    padding: 0 0 0 24px;
  }

  &.grid {
    >.right {
      padding: 20px 24px 17px 0;
      min-height: 32px;
    }
    >.left {
      padding: 20px 24px 17px 24px;
    }
  }

  &.analysis {
    >.right {
      padding: 20px 24px 17px 0;
    }
    >.left {
      padding: 20px 24px 17px 24px;
    }
  }
}

.metricHeaderExport {
  padding: 0 180px 20px 0;
  display: flex;
  flex-direction: column;
}

.exportRequestInfoSummaryWrapper {
  flex: 1 1 auto;
  display: flex;
  padding-left: 36px;
  color: $grey400;
  @include fontSize13;
  margin-top: 4px;
}

.dynamicDate {
  padding-left: 12px;
}

.divider {
  padding-right: 12px;
}
