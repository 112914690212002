.wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 20px 20px;
  flex-wrap: wrap;
  padding: 20px;
}

.pageBreakAfter {
  page-break-after: always;
}

.item {
  flex: 1 1 auto;
  max-width: 230px;
}
