@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.icon {
  font-size: 24px;
  vertical-align: middle;
}

.ellipsisWrapper {
  min-width: 0;
}

.name {
  @include fontSize15;
  padding-left: 12px;
}

.left {
  flex: 0 0 auto;
}

.right {
  min-width: 0;
  flex: 1 1 auto;
}
