@import "../../config/includes";

.tile {
  display: flex;
  flex: 1 1 auto;
  background-color: $white;
}

.sidebarContainer {
  z-index: 1;
  flex: 0 0 auto;
  width: 0;
  display: flex;
  position: relative;
  &.open {
    width: 281px;
    box-shadow: 0 2px 3px 0 rgba(72, 72, 72, 0.25);
    border-right: solid 1px $grey300;
  }
}

.contentContainer {
  z-index: 0;
  flex: 1 1 auto;
  display: flex;
  overflow: auto;
}

.sidebar {
  width: 280px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: $white;
  display: flex;
  flex-direction: column;
}

.toggle {
  border-top: solid 1px $grey300;
  border-right: solid 1px $grey300;
  border-bottom: solid 1px $grey300;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  @include pointer;
  width: 25px;
  height: 38px;
  position: absolute;
  top: 32px;
  right: -26px;
  font-size: 24px;
  display: flex;

  &:hover {
    border-color: $teal600;
    color: $blue700;
  }
}

.referenceWrapperClassName {
  display: flex;
  flex: 1 1 auto;
}

.toggleButtonManager {
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
}

.toggleButtonTarget {
  align-items:center;
}

.toggleButtonIcon {
  align-self:stretch;
  display:flex;
  align-items: center;
  flex: 1 1 auto;
}

.flexBody {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.quickAccessValue {
  @include fontSize15;
  font-weight: 600;
  display: flex;
  align-items: center;

  .icon {
    font-size: 24px;
    margin-right: 16px;
    flex: 0 0 auto;
  }

  .text {
    @include ellipsis;
    flex: 1 1 auto;
    min-width: 30px;
    overflow-y: hidden;
  }
}

.quickAccessListPadding {
  padding: 0 24px;
}

.noFavs {
  @include fontSize13;
  color: $grey500;
  padding: 0 24px;
}

.loadingError {
  @include fontSize13;
}

.analysisStartContent {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-color: $white;
  align-items: center;
  padding-bottom: 60px;
}

.analysisStartHeadline {
  width: 428px;
  @include fontSize18;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 60px;
}
