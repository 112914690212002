@import "../config/includes";

.button {
  display: block;
  padding: 12px 0;
  color: $white;
  @include fontSize15;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: $grey50;
    color: $mainNavigation;
    text-decoration: none;
  }
}

.active {
  background-color: $white;
  color: $mainNavigation;
}

