@import "config/includes";

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: normal;

  .checkboxWrapper {
    flex: 0 0 auto;
    padding: 20px 12px 20px 24px;
  }

  .dashboardInfoWrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 20px 0;

    .iconWrapper {
      flex: 0 0 auto;
      padding-left: 12px;

      .icon {
        font-size: 24px;
        color: $midnight600;
      }
    }

    .textWrapper {
      flex: 1 1 auto;
      padding-left: 16px;

      .name {
        @include fontSize15;
        font-weight: 600;
        color: $textColor;
      }

      .labels {
        margin-top: 4px;
        display: flex;
        align-items: stretch;
        list-style-type: none;

        .label {
          flex: 0 0 auto;
          @include fontSize13;
          color: $grey500;

          &:not(:first-child) {
            &:before {
              content: "·";
              vertical-align: middle;
              margin: 0 5px;
            }
          }
        }
      }
    }

    &:hover {
      @include pointer;

      :global {
        .dashboardName {
          color: $blue800 !important;
          text-decoration: underline !important;
        }
      }
    }
  }

  .buttonWrapper {
    padding: 20px 24px;
    flex: 0 0 auto;
  }

  &:hover :global(.list-hover-button-opacity) {
    opacity: 1;
  }
}
