@import "config/includes";

.skeletonList {
  height: 170px;
  overflow: hidden;
}

.loadingText {
  padding: 20px;
  color: $textColor;
  @include fontSize15;
  text-align: center;
}
