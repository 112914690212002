@import "config/includes";

.label {
  display: inline-block;
  padding: 4px 8px;
  border: 0;
  border-radius: 8px;
  background-color: $faceliftOrange;
  @include fontSize13;
  color: $white;
}
