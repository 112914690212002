.wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.checkbox {
  padding-right: 12px;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  min-width: 324px;
}

.detail {
  padding-right: 12px;
}
