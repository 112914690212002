@import "../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.sticky {
  background-color: $midnightGrey !important;
}

.backButton {
  padding-right: 20px;
}

.destinationTypeLogo {
  padding-right: 12px;
}

.placeholderIfFixedStyle {
  height: 66px;
}

.dataPushTaskStatus {
  padding-left: 12px;
}
