@import "../../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 8px 0;
}

.name {
  flex: 1 1 auto;
  padding-right: 22px;
  min-width: 0;
}

.ellipsisWrapper {
  min-width: 0;
}

.state {
  flex: 0 0 auto;
}

.icon {
  font-size: 24px;
}
