.wrapper {
  display: flex;

  >:nth-child(1) {
    flex: 0 0 50%;
    padding-right: 13px;
    box-sizing: border-box;
  }

  >:nth-child(2) {
    flex: 0 0 50%;
    padding-left: 13px;
    box-sizing: border-box;
  }
}
