@import "../config/includes";

.tile {
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0 1px 3px $grey400;
  height: 190px;
  border: 2px solid $white;
  border-radius: 8px;
  width: 244px;
  outline: none;
}

.header {
  height: 60px;
  margin: 0 24px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.icon {
  width: 18px;
  height: 18px;
  border-radius: 8px;
  background: $grey200;
  margin-right: 8px;
}

.text {
  width: 85px;
  height: 16px;
  border-radius: 8px;
  background: $grey200;
}

.content {
   flex: 1 1 auto;
   margin: 0 24px 20px;
   background: $grey200;
   border-radius: 8px;
}
