@import "config/includes";

.wrapper {
  display: block;
  width: 500px;
  max-height: 320px;
  overflow: auto;
  &.big {
    width: 620px;
  }
}

.noSearchResults {
  padding: 20px;
  color: $grey500;
  vertical-align: middle;
  text-align: center;
}
