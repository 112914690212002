@import "../config/includes";

.checkbox {
  width: 24px;
  padding: 0 0 0 24px!important;
}

.empty {
  width: 567px;
  height: 300px;
}

.icon {
  @include fontSize24;

  &:hover {
    color: $primaryActionColor;
  }
}

.disabled {
  opacity: 0.5;
}
