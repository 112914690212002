@import "../../../config/includes";

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sortDir {
  padding-right: 12px;
  font-size: 24px;
  color: $grey500;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.scroller {
  display: flex;
  align-items: center;
}

.pageInfo {
  padding-right: 12px;
}

.list {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button {
  padding-right: 12px;
}
