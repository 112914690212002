@import "../../../config/includes";
.wrapper {
  display: block;
}

.completed {
  font-size: 24px;
  color: $green900;
}

.emptyRules {
  color: $grey500;
  width: 20px;
  border-bottom: solid 2px $grey300;
  margin: auto;
}
