.info {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  min-width: 0;
}

.archive {
  width: 150px;
  flex: 1 0 auto;
}

.divWithTooltip {
  flex: 0 1 auto;
  min-width: 0;
}

.info {
  display: flex;
}

.numberOfPosts {
  padding-left: 6px;
  display: flex;
}