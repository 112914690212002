@import "config/includes";

.wrapper {
  width: 200px;
  border-radius: 8px;

  .scrollable {
    max-height: 240px;
    overflow-y: auto;
  }
}

.spacer {
  @include spacerNoMargin;
}
