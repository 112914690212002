@import "../config/includes";

.wrapper {
  border-radius: 100%;
  width: 8px;
  height: 8px;
  border: 1px solid $grey400;

}

.unRead {
  background-color: $blue400;
  cursor: pointer;

  &:hover {
    background-color: $blue500;
  }
}

.read {
  cursor: default;

  &:hover {
    border-color: $blue500 !important;
  }
}
