@import "../../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
}

.back {
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.backTitle {
  @include fontSize18;
  font-weight: 600;
}

.controls {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.icon {
  font-size: 24px;
  padding-right: 12px;
}

.delete {
  font-size: 24px;

  &:hover {
    cursor: pointer;
    color: $red600;
  }
}
