@import "config/includes";

.wrapper {
  display: flex;
  align-items: center;
  @include fontSize15;
  font-weight: 600;
  color: $grey500;

  .text {
    flex: 0 0 auto;
    margin-right: 8px;
  }

  .icon {
    flex: 0 0 auto;
    font-size: 20px;
    vertical-align: middle;
  }
}
