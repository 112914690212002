@import "../config/includes";

.wrapper {
  @include fontSize30;
  color: $midnight900;
  font-weight: 600;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $midnight900;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
    border-bottom: 2px solid $primaryActionColor;
  }
}
