@import "config/includes";

.linkWrapper {
  display: flex;
  align-items: center;
  min-width: 0;

  &:focus {
    outline: none;
  }
  color: $textColor;
  @include fontSize13;

  padding: 8px 24px;

  @include pointer;
  &:hover {
    background: $blue50;
    a {
      text-decoration: none;
    }
  }
  a {
    text-decoration: none;
  }
}

.tagIcon {
  padding-right: 8px;
}

.ellipsis {
  min-width: 0;
}

.analysisQuickLink {
  display: inline-block;
}
