@import "../../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  height: 274px;

  &:hover {
    .bodyWrapper {
      border: 1px solid $primaryActionColor;
    }

    :global {
      .hover-button-opacity {
        opacity: 1;
      }
    }
  }
}

.checkbox {
  padding-right: 20px;
}
.header {
  padding: 16px 16px 0 16px;
  @include pointer;
}
.bodyWrapper {
  background-color: $white;
  border: 1px solid $white;
  min-width: 0;
  flex: 1 1 auto;
  &.selected {
    border: 1px solid $primaryActionColor !important;
  }
}

.body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.postDetail {
  flex: 1 1 auto;
  display: flex;
  padding: 16px;

  @include pointer;
}

.detail {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.profileInfo {
  min-width: 0;
  height: 40px;
}

.postInfo {
  padding: 0;
}

.message {
  min-width: 0;
  @include fontSize13;
  height: 102px;
  overflow: auto;
  padding-bottom: 8px;
}

.imageWrapper {
  margin-right: 16px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.noMessage {
  color: $grey400
}

.kpis {
  overflow-x: auto;
  margin-bottom: -14px; // this avoids to not push down the bellow content
}

.postTags {
  padding: 0 16px;
}
