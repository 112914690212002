@import "../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.body {
  flex: 1 1 auto;
  display: flex;
  overflow: auto;
  z-index: 0;
}

.sidebar {
  flex: 0 0 auto;
  display: flex;
  z-index: 1;
}

.oldSidebar {
  flex: 0 0 auto;
  padding-top: 26px;
  width: 280px;
  display: flex;
  position: relative;
  background-color: $white;
  box-shadow: -2px -2px 5px 0 rgba(163, 163, 163, 0.25);
  border-left: $grey400;
  z-index: 1;
}
