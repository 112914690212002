@import "config/includes";

.listHeader {
  display: flex;
  padding: 24px 24px 0 24px;
  background: $midnightGrey;
}

.checkOption {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px 16px 0;
}

.listNumber {
  color: $grey500;
  @include fontSize15;
  padding-left: 20px;
}

.filterBar {
  flex: 1 1 auto;
  display: flex;
  &.withoutCheckbox {
    margin-left: -10px;
  }
}
