@import "config/includes";

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .checkboxWrapper {
    flex: 0 0 auto;
    padding: 20px 12px 20px 24px;
  }

  .folderInfoWrapper {
    flex: 1 1 auto;
    padding: 20px 0;

    &.superRoot {
      padding-left: 12px;
    }

    &:hover {
      @include pointer;

      :global {
        .folderName {
          color: $blue800 !important;
          text-decoration: underline !important;
        }
      }
    }
  }

  .buttonWrapper {
    padding: 20px 24px;
    flex: 0 0 auto;
  }

  &:hover :global(.list-hover-button-opacity) {
    opacity: 1;
  }
}
