@import "../config/includes";

.backButton {
  padding-right: 20px;
}

.sections {
  padding-right: 24px;
}

.warningIcon {
  font-size: 24px;
  padding-right: 16px;
}

.headline {
  display: flex;
  align-items: center;
}

.discover {
  padding-right: 10px;
}
