@import "config/includes";

.tile {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;

  &:hover :global(.grid-hover-button-opacity) {
    opacity: 1;
  }
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  &.noFlex {
    display: block;
    flex: none;
  }
}

.metricBodyWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px 20px 24px;
}

.resultBodyWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.analysisMetricBodyWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px 20px 24px;
}

.maximizedSharedDashboardMetricBodyWrapper {
  flex: 1 1 auto;
  display: flex;
  margin-top: 32px;
  height: calc(80vh - 81px - 76px); // 81px for footer, 76px for header
  min-height: 325px;
}

.warningWrapper {
  flex: 0 0 auto;
  margin-bottom: 32px;
}

.sampleLabel {
  padding-bottom: 24px;
}

.filterWrapper {
  flex: 0 0 auto;
  display: flex;
}

.notesWrapper {
  border-top: 1px solid $grey300;
  &.notesHover {
    &:hover {
      border-color: $primaryActionColor;
    }
  }
}

.apiBoxWrapper {
  flex: 0 0 auto;
  border-top: 1px solid $grey300;
  padding: 20px 24px;
}

.gridFilterWrapper {
  padding: 0 24px 20px 24px;
}

.maximizedMetricFilterWrapper {
  border-top: 1px solid $grey300;
  padding: 20px 0;
}

.sharedDashboardGridFilterWrapper {
  padding: 0 24px 20px 24px;
}

.analysisMetricFilterWrapper {
  border-top: 1px solid $grey300;
  padding: 20px 24px;
}

.qqlMetricFilterWrapper {
  padding: 0 24px 20px 24px;
}

// export context is handled in separate exportDataTile.scss
