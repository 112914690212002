.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container {
  width: 100%;
  position: absolute;
}

.carousel {
  display: block;
  padding-left: 26px;
  padding-right: 26px;
}

.card {
  flex: 1 1 auto;
  display: flex;
  padding: 12px;
}
