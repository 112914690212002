@import "../config/includes";


.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.header {
  height: 92px;
  background-color: $white;
  flex: 0 0 auto;
}

.subHeader {
  background-color: $midnight100;
  height: 48px;
  flex: 0 0 auto;
}

.body {
  flex: 1 1 auto;
  display: flex;
  .left, .right {
    flex: 1 1 auto;
  }

  .left {
    background-color: $midnight900;
  }

  .right {
    background-color: $white;
  }

  .sideBar {
    flex: 0 0 auto;
    width: 401px;
    background-color: $white;
  }
}

.footer {
  flex: 0 0 auto;
  height: 72px;
  background-color: $midnight100;

}
