@import "../../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  @include fontSize24;
  padding-right: 12px;
}

.name {
  min-width: 0;
  @include fontSize15;
}

.item {
  padding-bottom: 20px;
}