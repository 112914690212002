@import "../config/includes";

.dataSource {
  display: inline-flex;
  padding: 3px 8px;
  border: 0;
  border-radius: 12px;
  background-color: $grey200;
  @include fontSize13;
  color: $textColor;
  outline: none;
  align-items: stretch;
}

.dataSources {
  list-style-type: none;
  overflow: auto;
  padding: 0;
  margin-bottom: -12px;

  > li {
    float: left;
    margin-right: 12px;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}
