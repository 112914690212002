@import "../../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.noAvailable {
  width: 16px;
  height: 2px;
  background-color: $grey400;
  margin-right: 16px;
  margin-left: 4px;
}
