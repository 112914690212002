@import "../../config/includes";

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.titleWrapper {
  padding-bottom: 24px;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.item {
  padding-bottom: 20px;
}
