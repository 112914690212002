@import "config/includes";

.list {
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;

  &.rightAlign {
    justify-content: flex-end;
  }

  &.small {
    min-width: 500px;
  }
}

.dateSelector, .profileSelector {
  z-index: 0;
}

.dateSelector {
  flex: 0 0 auto;
}

.profileSelector {
  flex: 0 1 auto;
  min-width: 0;
  display: flex;
  margin-right: 10px;
}

.forSummary {
  .profileSelector {
    margin-right: 40px;
    min-width: 150px;
  }
}

.higherZIndex {
  z-index: 1;
}

.filterSelector {
  padding-left: 12px;
}
