.wrapper {
  flex: 1 1 auto;
  display: flex;
  overflow: auto;
}

.loading {
  opacity: 0.5;
}

.resultList {
  margin: 0 -12px -30px;
}