@import "config/includes";

.wrapper {
  display: inline-flex;
  padding: 3px 8px;
  border: 0;
  border-radius: 12px;
  background-color: $midnight100;
  @include fontSize13;
  outline: none;
  align-items: center;
  color: $midnight600;

  @include pointer;
  &:hover {
    background-color: $midnight600;
    color: $midnight100;
    text-decoration: none;
  }
}

.icon {
  font-size: 16px;
}

.name {
  padding-left: 8px;
}
