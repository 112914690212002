@import "../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.icon {
  font-size: 24px;
}

.label {
  padding-top: 4px;
  @include fontSize10;
  font-weight: 400;
  text-align: center;
}
