@import "../config/includes";

.noInfoText {
  color: $grey400;
}

.texts {
  @include fontSize15;
  color: $textColor;

  > p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bottomPadding {
  padding-bottom: 24px;
}
