@import "config/includes";

body {
  background-color: $midnightGrey;
}

.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.loadingBarContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 10px;
  z-index: ($modalBase + 30);
}

.loadingBar {
  background-color: $faceliftOrange;
  height: 3px;
  position: absolute;
}

.navContents {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.sidebarWithContent {
  display: flex;
  flex: 1 1 auto;
}

.sidebar {
  display: flex;
  flex: 0 0 auto;
}
