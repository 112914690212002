@import "../../config/includes";

.wrapper {
  display: flex;
  align-items: center;
  color: $midnight900;
}

.changeIcon {
  font-size: 24px;
}
.value {
  font-weight: 600;
}
.changePercentValue {
  color: $grey400;
}

.big {
  .changeIcon {
    font-size: 48px;
  }
  .value {
    @include fontSize24;
    font-weight: 600;
  }
}

.green {
  color: $green600;
}

.red {
  color: $red600;
}
