@import "../../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 24px 20px 24px;
}

.iconWrapper {
  padding-right: 16px;
  display: flex;
  align-items: center;
}

.icon {
  color: $grey400;
  font-size: 32px;
}

.info {
  @include fontSize13;
  color: $grey500;
}

.modifiedDate {
  padding-top: 2px;
}

.createdByInfo {
  display: flex;
}

.dateInfo {
  display: flex;
}