@import "config/includes";

.wrapper {
  @include fontSize15;
  color: $textColor;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .textWrapper {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }

  .orderByWrapper {
    flex: 0 0 auto;
  }
}

.summaryLineText {
  margin-right: 12px;
}

.clearAll {
  padding-left: 12px;
}

.clearAllText {
  @include fontSize15;
  font-weight: 600;
  color: $blue800;

  &:hover {
    text-decoration: none;
    .buttonText {
      text-decoration: underline;
    }
  }

}

.clearAllIcon {
  font-size: 24px;
  color: $blue800;
  position: relative;
  top: -1px;
}
