@import "../../config/includes";

.sectionWrapper {
  display: flex;
}

.section {
  flex: 50%;
}

.description {
  padding-bottom: 24px;
}

.infoWrapper {
  font-size: 15px;
}

.title {
  font-weight: 600;
  padding-bottom: 8px;
  color: $textColor;
}

.text {
  padding-bottom: 12px;
}

.copyText {
  display: flex;
}

.clientId {
  padding-bottom: 24px;
}
