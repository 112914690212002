@import "config/includes";

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $textColor;
  height: 52px;
  background-color: $white;

  &:hover {
    background-color: $blue50;
  }

  &:hover :global(.list-hover-button-opacity) {
    opacity: 1;
  }

}

.left {
  flex: 1 1 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  min-width: 280px;
}

.checkboxWrapper {
  padding: 0 20px;
}

.accountInfoWrapper {
  flex: 1 1 auto;
  min-width: 0;
  width: 0;   // magically works to fully shrink the div

  @include pointer;
  &:hover {
    :global {
      .profileNameForHover {
        color: $blue800 !important;
        text-decoration: underline !important;
      }
    }
  }
}

.right {
  flex: 1 1 65%;
  padding: 0 24px 0 12px;
  display: flex;
  align-items: center;
  height: inherit;
  width: 0;
  min-width: 500px;


  &:hover {
    :global {
      .groupEditButton {
        opacity: 1;
      }
    }
  }

  :global {
    .groupEditButton {
      opacity: 0;
    }
  }
}

.analysis {
  width: 158px;
  flex: 0 0 auto;
}


.groups {
  flex: 0 1 auto;
  display: flex;
  min-width: 0; // ellipsis
  padding: 0 8px 0 40px;
}


.buttonWrapper {
  margin-left: auto;
}
