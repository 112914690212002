@import "../config/includes";


.item {
  height: 16px;
  border-radius: 8px;
  background: $grey200;
  margin-bottom: 16px;
}

.list {
  list-style-type: none;

  li {
    &:nth-child(4n+1) {
      .item {
        width: 105px;
      }
    }
    &:nth-child(4n+2) {
      .item {
        width: 126px;
      }
    }
    &:nth-child(4n+3) {
      .item {
        width: 105px;
      }
    }
    &:nth-child(4n+4) {
      .item {
        width: 95px;
      }
    }
  }
}
