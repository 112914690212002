@import "../../config/includes";

.notificationItem {
  margin-bottom: 8px;
  background-color: $white;
}

.emptyAndLoadMore {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
