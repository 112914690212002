@import "../config/includes";

.popover {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 20px 0;
  padding: 0 20px;
  min-width: 300px;
  max-width: 500px;
  max-height: 300px;
  position: relative;
}
