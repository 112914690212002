@import "config/includes";

.futureSwaps {
  @include fontSize15;
  color: $textColor
}

.row {
  &.horizontal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    font-weight: 600;
    @include fontSize13;
  }
  margin-bottom: 24px;
}

.innerContainer {
  margin-bottom: 8px;
}
