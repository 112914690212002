@import "../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.sticky {
  background-color: $midnightGrey !important;
}

.backButton {
  padding-right: 20px;
}
