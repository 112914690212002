@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  flex: 1 1 auto;
  padding: 0 24px 24px 0;
}

.logSection {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.logTitle {
  font-weight: 600;
  padding-bottom: 12px;
}

.log {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logEditor {
  width: 100%;
  height: 100%;
}

.error {
  padding-bottom: 24px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleImg {
  padding-right: 12px;
}

.tableName {
  padding-right: 12px;
}

.dryRunMoreInfo {
  font-size: 16px;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}
