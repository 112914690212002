@import "config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
}

.iconEnabled {
  @include pointer;
  font-size: 24px;
}

.iconDisabled {
  color: $grey300;
  font-size: 24px;
}

.iconWrapper {
  line-height: 1;
  // Use this trick to avoid extra pixels on top of the i-tag inside: https://stackoverflow.com/a/19212391
  font-size: 0;
}
