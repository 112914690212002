@import "config/includes";

.button {
  box-sizing: border-box;
  background: none;
  border: none;
  outline: none;
  color: $grey300;
  cursor: move;
  .icon {
    font-size: 24px;
  }

  &:hover, &:active, &.active {
    color: $blue700;
  }

  &.disabled {
    cursor: default;
    &:hover, &:active, &.active {
      color: $grey300;
    }
  }
}
