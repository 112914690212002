@import "../../config/includes";

.listItem {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  color: $textColor;

  &:hover {
    :global(.list-hover-button-opacity) {
      opacity: 1;
    }
  }

  .metricWrapper {
    flex: 1 1 auto;
    min-width: 350px;
    padding: 20px 24px;

    .nameAndSummary {
      @include pointer;
      &:hover {
        :global {
          .customMetricNameForHover {
            color: $blue800 !important;
            text-decoration: underline !important;
          }
        }
      }

      .summary {
        padding-top: 12px;
        @include fontSize13;
        color: $textColor;

        // add ellipsis on the text
        @include ellipsis;
        overflow: hidden;
      }
    }

    .dataSources {
      padding-top: 12px;
    }
  }

  .buttonWrapper {
    padding: 20px 24px;
    flex: 0 0 auto;
  }
}
