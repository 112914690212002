@import '../config/colors';

.dateSelectorWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.section {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.sectionTitle {
  font-size: 15px;
  padding-bottom: 24px;
  font-weight: 500;
}

.dateSelectorsTitle {
  padding-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
}

.dateSelectors {
  display: flex;
  flex-direction: row;
}

.startDate {
  padding-right: 20px;
  flex: 50%;
}

.endDate {
  flex: 50%;
  padding-right: 20px;
}

.timezone {
  flex: 0 0 auto;
}

.timezoneSelect {
  width: 120px;
}

.dateSelectorHelperWrapper {
  display: flex;
  padding-bottom: 6px;
}

.dateSelectorLabel {
  flex: 1 1 auto;
  font-size: 15px;
  font-weight: 500;
}

.dateSelectorHelper {
  display: flex;
  color: $primaryActionColor;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}

.textFilter {

}

.conjunctionWrapper {
  display: flex;
}

.postTextFilter {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.conjunctionLabel {
  padding-right: 12px;
}

.conjunction {
  display: flex;
}

.keyword {
  padding-left: 12px;
}
