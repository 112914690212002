@import "../../config/includes";

.wrapper {
  display: flex;
  color: $grey500;
}

.small {
  @include fontSize18;
}

.big {
  @include fontSize24;
}