@import "config/includes";

.wrapper {
  border-radius: 5px;
  background: $white;
  text-align: center;
  box-shadow: 0 12px 40px rgba(0,0,0,0.12);
  padding: 60px;
  margin: 0 12px;
}

.background {
  background: $white;
}


.logo {
  width: 80px;
  height: 80px;
}

.headline {
  @include fontSize30;
  padding: 12px 0;
}

.text {
  @include fontSize15;
  padding: 12px 0;
}

.button {
  margin-top: 24px;
  display: inline;
}
