@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  background-color: $white;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.enabled {
  &:hover {
    cursor: pointer;
    .icon, .label {
      color: $primaryActionColor;
    }
  }
}

.disabled {
  cursor: default;
  .icon, .label {
    opacity: 0.5;
  }
}

.active {
  .icon, .label {
    color: $primaryActionColor;
  }
}

.icon {
  font-size: 24px;
  padding-bottom: 8px;
}

.label {
  @include fontSize13;
}

