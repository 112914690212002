@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.body {
  flex: 1 1 auto;
  display: flex;
  overflow: auto;
}

.headline {
  color: $textColor;
  @include fontSize18;
  font-weight: 600;
  padding-bottom: 24px;
  padding-left: 24px;
}

.sectionWrapper {
  display: flex;
}

.rightSection {
  flex: 50%;
  max-width: 50%;
}

.leftSection {
  flex: 50%;
  padding-right: 24px;
  max-width: 50%;
}

.text {
}

.noAccess {
  display: flex;
  justify-content: center;
}

.plan {
  display: flex;
  justify-content: space-between;
}

.notCanceled {
  padding-bottom: 11px; // this padding is to fix the alignment of left block with the right side block
}

.canceled {
  padding-bottom: 24px;
}
