@import "../config/includes";

.section {
  background-color: $white;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.sectionBody {
  padding-bottom: 16px;
}

.headline {
  color: $textColor;
  @include fontSize18;
  font-weight: 600;
  padding-bottom: 24px;
}

.footer {
  display: flex;
  flex: 0 0 auto;
  padding-top: 20px;
  margin-top: 24px;
  border-top: 1px solid $grey300;
  justify-content: flex-end;
}

.footerRight {
  align-self: flex-end;
}

.footerLeft {
  flex: 1 0 auto;
  justify-content: flex-start;
}
