@import "config/includes";

.wrapper {
  .progressBarWrapper {
    margin-bottom: 24px;
  }

  &.horizontal {
    display: flex;
    justify-content: space-between;

    .progressBarWrapper {
      flex: 0 0 47%; // TODO: horizontal mode is currently only built for exactly 2 elements
      margin-bottom: 0;
    }
  }
}
