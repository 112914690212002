@import "../../../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  font-size: 24px;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor
  }
}

.deleteIcon {
  font-size: 24px;

  &:hover {
    cursor: pointer;
    color: $red600
  }
}

.completed {
  font-size: 24px;
  padding-right: 12px;
  color: $green900;
}

.edit {
  padding-right: 12px;
}

.description {
  flex: 1 1 auto;
  padding-right: 24px;
}

.disabled {
  opacity: 0.5;
}

.controls {
  display: flex;
  flex-direction: row;
}
