@import "config/includes";

.container {
 @include fontSize13;
  padding: 20px 24px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: $primaryActionColor
  }
  flex: 1 1 auto;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.wrapper {
  flex: 1 1 auto;
}
