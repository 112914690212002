@import 'config/includes';

:global {
  .react-grid-item {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 $grey400;
    border: 2px solid $white;

    &:hover {
      border: 2px solid $primaryActionColor;
    }

    &.react-grid-placeholder {
      background: $midnight200;
      box-shadow: none;
      z-index: 0;
    }

    > .react-resizable-handle {
      height: 0;
      width: 0;
      background: none;
      bottom: -6px !important;
      right: -3px !important;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid $grey300;
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      padding: 0 3px 3px 0;

      &:hover {
        border-left: 7px solid $primaryActionColor;
      }

      &:after {
        content: none;
      }
    }

    &.break-sm, &.readOnly {
      > .react-resizable-handle {
        display: none;
      }
    }

    &.break-lg {
      &.loading {
        > .react-resizable-handle {
          pointer-events: none;
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
      &.resizing {
      > .react-resizable-handle {
        border-left: 7px solid $primaryActionColor;
      }
    }
  }
}

.reactGridTile {
  display: flex;
  flex-direction: column;
  background: $white;
}
