@import "../../config/includes";

.wrapper {
  display: flex;
  align-items: center;
  height: 52px;
  min-width: 750px;
  background-color: $white;
}

.left {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 14px 24px;
  min-width: 0;
}

.right {
  flex: 0 0 auto;
  padding: 14px 24px;
}

.link {
  &:hover {
    text-decoration: none;
  }
}
