@import 'config/includes';

.header {
  flex: 0 0 auto;
  margin: 18px 24px;
  display: flex;

  .networkIcon {
    flex: 0 0 auto;
    font-size: 24px;
    margin-right: 12px;
    margin-top: 2px;
    margin-bottom: auto;
  }

  .name {
    flex: 1 1 auto;
    color: $textColor;
    @include fontSize18;
    font-weight: 600;
  }

  .icon {
    flex: 0 0 auto;
    font-size: 24px;
    margin-left: 10px;
    margin-top: 2px;
    margin-bottom: auto;
  }
}
