@import "../../config/includes";

.wrapper {
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  min-width: 800px;
  background-color: $white;
}

.header {
  display: flex;
  align-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.left {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 14px 24px;
  min-width: 0;
}

.right {
  flex: 0 0 auto;
  padding-right: 24px;
}

.disabled {
  pointer-events: none;
}

.disabledIcon {
  opacity: 0.5;
}

.activeHeader {
  background-color: $blue100;

  &:hover {
    background-color: $blue200;
  }
}

.container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.body {
  border-top: 1px solid $grey300;
  padding: 24px 68px;
}

.footer {
  padding: 14px 60px 14px 70px;
}

.icon {
  font-size: 24px;
}

.toggleButton {
  padding-right: 20px;
}

.warning {
  padding-right: 12px;
}

.connectedProfileCount {
  flex: 1;
  display: flex;
  align-items: center;
}

.items {
  flex: 1;
}

.network {
  padding-bottom: 20px;
}

.text {
  min-width: 0;
}
