@import "../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  background: $midnightGrey;
  padding-top: 24px;
}

.left {
  flex: 0 0 auto;
}

.right {
  flex: 1 1 auto;
}
