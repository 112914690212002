.wrapper {
  width: 100%;
  height: 100%;
}

.empty {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
