@import "config/includes";
/* z-index = 1040 -1 to be a level below modals */

.agentBar {
  position: fixed;
  background-color: $faceliftOrange;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: ($modalBase - 1);
  color: $white;
  @include fontSize13;
  padding: 1px 10px;
}
