.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  padding-right: 6px;
}

.items {
  flex: 1 1 auto;
}