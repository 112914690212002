@import "../../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.controls {
  display: flex;
  flex-direction: column;
  padding: 26px 24px;
  background-color: $white;
  justify-content: center;
  flex: 0 0 auto;

  >.button {
    margin-bottom: 10px;
  }
}

.selectionInfo {
  padding: 0 24px 12px 24px;
}

.empty {
  display: flex;
  align-items: center;
  padding-top: 24px;

  .none {
    padding-left: 12px;
    color: $grey400;
  }
}

.selectedPosts {
  overflow-y: auto;
}

.postListItem {
  padding-right: 24px;

  &:hover {
    background-color: $blue50;
    i { // this is for the deselect icon to have similar background as hover
      background-color: $blue50;
    }
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    @include fontSize15;
    font-weight: 600;
    color: $textColor;
  }

  .iconWrapper {
    line-height: normal;

    .icon {
      padding: 0 12px;
      font-size: 24px;
      color: $grey500;
      @include pointer
    }
  }
}

.remainingPostCounts {
  display: flex;
  padding-bottom: 24px;
  justify-content: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
