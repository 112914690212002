@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.title {
  font-weight: 600;
  padding: 33px 24px 9px 24px;
}

.item {
  padding: 0 24px;

  &:hover {
    cursor: pointer;
    background-color: $blue50;
  }
}

.separator {
  margin: 12px 0;
  border: 1px dashed $grey300;
}

.button {
  padding: 16px 24px 0 24px;
}

.empty {
  color: $grey300;
  padding: 9px 24px;
}
