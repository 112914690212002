@import "../config/includes";

.wrapper {
  width: 340px;
}

.header {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $grey300;

  .left {
    @include fontSize13;
    font-weight: 600;
    color: $textColor;
  }

  .right {
    display: flex;
    align-items: center;

    .setting {
      font-size: 24px;
      color: $grey400;

      &:hover {
        cursor: pointer;
      }
    }

    .markAsRead {
      @include fontSize13;
      padding-right: 5px;
    }

    .disabled {
      color: currentColor;
      opacity: 0.5;
      text-decoration: none;
      pointer-events: none;
    }
  }
}

.footer {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: center;
  border-top: solid 1px $grey300;
  @include fontSize13;
  font-weight: 600;
  color: $textColor;
}

.body {
  display: flex;
  flex-direction: column;
}

.notificationItem {
  padding-bottom: 2px;
  flex: 1 0 auto;
}

.lastNotification {
  padding-bottom: 0;
}

.loadingAndEmpty {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
}

.noNotificationWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}

.noNotificationText {
  padding-top: 20px;
}
