@import "config/includes";

.wrapper {
  @include fontSize15;
  color: $textColor;
}


.dataSourceHint {

  &.small {
    padding-bottom: 8px;
  }

  &.big {
    padding-bottom: 24px;
  }

  &:last-child {
    padding-bottom: 0;
  }
}
