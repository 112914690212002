@import "../../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 24px;
  padding-right: 12px;
}

.success {
  color: $green700;
}

.alert {
  color: $yellow600;
}

.close {
  color: $red600;
}
