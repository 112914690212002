@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
}

.left {
  flex:  1 0 50%;
  margin-right: 24px;
  position: relative;
}

.right {
  flex: 50%;
}

.detailText {
  padding-bottom: 20px;
}

.logs {
  padding-top: 16px;
}

.frequencyIndicator {
  position: absolute;
  top: 18px;
  right: -12px;
}
