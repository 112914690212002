@import "../../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.tileBackgroundColor {
  background-color: $grey50!important;
}

.timeWrapper {
  flex: 1 1 auto;
  display: flex;
  padding-bottom: 24px;
  align-items: center;
}

.createTime {
  @include fontSize13;
  padding: 0 12px;
}

.horizontalLine {
  flex: 1 1 auto;
  border-bottom: 1px solid $grey300;
}
