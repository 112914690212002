.info {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
}

.more {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
}

.moreLink {
  flex: 0 0 auto;
}

.futureSwaps {
  margin-bottom: 16px;
  margin-top: -8px;
}
