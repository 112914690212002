@import "../config/includes";

.hasNotification {
  color: $blue300;
}

.active {
 &.hasNotification {
    color: $primaryActionColor;
  }
}
