@import "config/includes";

.wrapper {

}

.titleWrapper {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .headerLeft {
    flex: 0 1 auto;
    display: flex;
    min-width: 500px;
  }

  .headerRight {
    padding-left: 10px;
    flex: 0 0 auto;
  }
}

.metric {
  margin-top: 32px;
  height: calc(80vh - 81px - 76px); // 81px for footer, 76px for header
  min-height: 325px;
  display: flex;
}

.metaData {
  margin-top: 32px;
  display: flex;
  flex-direction: row;

  .section {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  .headlineIcon {
    position: relative;
    top: -1px;
    margin-right: 5px;
  }

  .sectionHeadline {
    @include fontSize18;
    color: $textColor;
    font-weight: 600;
    margin-bottom: 16px;
  }


  .meta {
    color: $grey500;
    @include fontSize15;
    margin-top: 12px;
  }

  .texts {
    @include fontSize15;
    color: $textColor;

    > p {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .metaDataLeft {
    box-sizing: border-box;
    flex: 1 1 50%;
    padding-right: 50px;
  }

  .metaDataRight {
    flex: 1 1 50%;

    .qql {
      background-color: $grey200;
      padding: 20px;
      word-break: break-word;
    }
  }
}
