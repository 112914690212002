@import "../../config/includes";

.wrapper {
  display: flex;
}

.rightSection {
  flex: 50%;
  background-color: $white;
}

.leftSection {
  margin-right: 24px!important;
  flex: 50%;
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  border-radius: 8px;
  justify-content: space-between;
}

.infoItem {
  flex: 1 1 0px;
  border-right: 1px solid $grey400;
}

.infoItemWithoutRightBorder {
  flex: 1 1 0px;
}

.descriptionSection {
  background-color: $white;
  flex: 1 1 auto;
  margin-bottom: 0;
}

.description {
  word-break: break-word;
}

.deprecationInfo {
  padding-top: 24px;
}

.right {
  flex: 0 0 auto;
  display: flex;
  align-items: start;
  padding-left: 12px;
}

.notAvailable {
  display: flex;
  align-items: center;
}

.notAvailableLabel {
  font-weight: 600;
  padding-left: 12px;
}

.left {
  flex: 1 1 auto;
}

.timeSeries {
  padding-bottom: 24px;
}

.type {
  padding-left: 12px
}

.icon {
  font-size: 24px;
}

.hint {
  padding-top: 24px;
}

.emptyHints {
  color: $grey300;
}
