@import "../../config/includes";

.row {
  &:hover {
    cursor: default!important;
    .linkIcon {
      visibility: visible;
    }
  }

  &:focus {
    background-color: $blue100;
    outline: none;
  }

  &.deprecatedRow {
    font-style: italic;

    &:hover {
      background-color: $red50;
      outline: none;
    }

    &:focus {
      background-color: $red100;
      outline: none;
    }
  }
}

.nameAndLink {
  display: flex;
  align-items: center;
  position: relative;
}

.name {
  flex: 1 1 auto;
  padding-right: 12px;
}

.deprecatedIconWrapper {
  flex: 0 0 auto;
}

.deprecatedIcon {
  color: $red600;
  font-size: 24px;
}

.linkIcon {
  flex: 0 0 auto;
  visibility: hidden;
}

.column {
  vertical-align: top;
  @include fontSize15;
  padding-top: 20px;
  padding-bottom: 20px;
}

.headerColumn {
  text-align: left;
  font-weight: 400;
  height: 40px;
  z-index: 1;
}

.descriptionAndIconWrapper {
  display: flex;
  align-items: center;
}

.description {
  flex: 0 1 auto;
  max-width: 600px;
}

.nestedIntervalSpecificColumnsIcon {
  flex: 0 0 auto;
  font-size: 24px;
  margin-left: 8px;
  color: $grey500;

  &:hover {
    color: $primaryActionColor;
  }
}

.nestedIntervalSpecificColumnsTooltip {
  text-align: left;
}
