.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: -32px;
  
  > div {
    margin: 0 16px 32px;
  }
}
