@import "../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.search {
  flex: 0 0 auto;
  padding: 26px 24px;
  border-bottom: solid 1px $grey300;
}

.quickAccessWrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 16px;
}

.quickAccess {
  padding: 20px 0 0;
}

.sectionHeadline {
  color: $textColor;
  @include fontSize13;
  font-weight: 600;
  display: block;
  padding: 0 24px 16px;
}

.divider {
  margin: 20px 24px;
  display: block;
  border: none;
  border-top: solid 1px $grey300;
}
