@import "../config/includes";

.wrapper {
  background-color: $white;
  border-top: solid 1px $grey300;
  border-left: solid 1px $grey300;
  border-bottom: solid 1px $grey300;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  @include pointer;
  width: 25px;
  height: 38px;
  position: absolute;
  top: 26px;
  left: -26px;
  font-size: 24px;
  display: flex;

  &:hover {
    border-color: $teal600;
    color: $blue700;
  }
}

.toggleButtonIcon {
  align-self: stretch;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.referenceWrapperClassName {
  display: flex;
  flex: 1 1 auto;
}

