@import "../../config/includes";

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  .userInfoWrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    padding: 20px 0 20px 25px;
    height: 80px;
  }

  .userDetail {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
  }

  .userName {
    @include fontSize18;
    font-weight: 600;
    color: $textColor;
  }

  .smallDetail {
    @include fontSize15;
    margin-top: 4px;
    color: $grey500;
  }

  .smallestDetail {
    @include fontSize13;
    margin-top: 4px;
    color: $grey500;
  }

  .buttonWrapper {
    padding: 20px 24px;
    flex: 0 0 auto;
  }
}
