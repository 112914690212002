@import "../../config/includes";

.statusWithActionCell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status {
  flex: 1 1 auto;
}

.addCustomLogo {
  display: flex;
  color: $grey500;
  align-items: center;
}

td:hover .addCustomLogo {
  color: $primaryActionColor;
  cursor: pointer;
}

.icon {
  font-size: 24px;
  padding-right: 6px;
}
