@import "../../../config/includes";

.wrapper {
  display: flex;
  align-items: center;
  padding: 12px 0 12px 24px;
}

.icon {
  font-size: 24px;
  color: $grey500;
  padding-right: 16px;
}

.name {
  @include fontSize15;
  min-width: 0;
}
